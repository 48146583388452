import { useEffect, useState } from "react"
import axios from "axios"
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import ChipComponent from "../../../../components/Chip/index.jsx";
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpAlt'
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Info from "@mui/icons-material/Info";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";


export default function SimilarShoesList( { recommendationId = null, shoe = null, preferences = null, requirements = null, shoeColorway = null, setShoeColorway = null }) {

    const { isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent } = useAppContext()

    var [similarShoes, setSimilarShoes] = useState()

    var [fetchingSimilarShoes, setFetchingSimilarShoes] = useState(false)

    var navigate = useNavigate()

    useEffect(()=> {
        if (shoe) {
            setFetchingSimilarShoes(true)
            var url = `${requestUrl}/api/recommendations/${recommendationId}/shoes/${shoe?.shoe._id}/similar-shoes`
            axios({
                url: url,
                withCredentials: true
            }).then((res) => {
                setSimilarShoes(res.data?.similarShoesList || null)
                setFetchingSimilarShoes(false)
            }
            ).catch((err)=> {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setFetchingSimilarShoes(false)
        })
        }
        
    }, [shoe])

    const handleClick = (evt, shoe, colorwayIndex) => {
        var url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`
        trackEvent('View similar shoe')
        setShoeColorway(shoe.shoe.colorways[colorwayIndex])
        navigate(url, {state: {shoe: shoe, preferences: preferences, requirements: requirements  }})
    }

    if (fetchingSimilarShoes) {
        return (
            <div className="col">
                <div className="row mb-2">
                    <div className="col-auto">
                        <h2>Similar shoes</h2>
                    </div>
                    <div className="col-auto px-0 ">
                        <Tooltip title="Similar shoes in your recommendation, based on price, brand, cushion and width.">
                            <InfoIcon/>
                        </Tooltip>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Skeleton height={80} variant="rounded" animation="wave" />
                    </div>
                </div>
            </div>

        )
    } else if (shoe) {
        return (
            <div className="col">
                <div className="row">
                    <div className="col-auto">
                        <h2>Similar shoes</h2>
                    </div>
                    <div className="col-auto px-0 ">
                        <Tooltip title="Similar shoes in your recommendation, based on price, brand, cushion and width.">
                            <InfoIcon/>
                        </Tooltip>
                    </div>
                </div>
                <div className="row">
                    {similarShoes?.slice(0, 3).map((shoe) => {
                        var colorwayIndex = shoe.shoe.colorways?.indexOf(shoe.shoe.colorways.filter((colorway) => colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)))[0]) 

                        if (colorwayIndex == -1) {
                            colorwayIndex = 0
                        }

                        return(
                            <div key={`${shoe.shoe._id}SimilarShoeTile`} className="col-12 col-md col-xl-12 mx-2 my-2 pt-2 pb-3 px-2  show-shoe-tile">
                                <a href={`/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`}>
                                    <Button
                                    disableFocusRipple
                                    disableRipple
                                    style={{ backgroundColor: 'transparent', textTransform: 'none', color: 'inherit'}} 
                                    onClick={(evt) => handleClick(evt, shoe, colorwayIndex)}
                                    key={`${shoe.shoe._id}SimilarShoeCard`}
                                    >
                                        <div className="col">
                                            <div className="row mt-3">
                                                <div className="col-auto mx-auto">
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {shoe.criteria.map((criteria) => {
                                                                    var string = criteria.trait.split(/(?=[A-Z])/);
                                                                    var finalString = string.join(' ').toLowerCase()
                                                                    if (requirements[criteria.trait]?.reasoning?.length > 0) {
                                                                    return (
                                                                    <div key={`${criteria.trait}TraitRow`} className="row">
                                                                        <div className="col">
                                                                            {criteria.pass == 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                })}
                                                            </span>
                                                        }
                                                        >
                                                            <span>
                                                            <ChipComponent
                                                                icon={
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? <StarRateIcon /> : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <ThumbUpOffAltIcon /> : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                                                    
                                                                }
                                                                label={`${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match`}
                                                                color={
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                                                    
                                                                }
                                                                variant={((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? null : 'outlined'}
                                                                href={`/dashboard/recommendations/${recommendationId}/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}#matching-information`}
                                                            />
                                                            </span>
                                                            
                                                    </Tooltip>
                                                    
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6 mx-auto text-center">
                                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe.brand.slug}.png`} alt="" />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-10 mx-auto text-center">
                                                    <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoe?.shoe.colorways[colorwayIndex]?.colorwayCode}.png`} alt="" />
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col text-center">
                                                    <h3 style={{color: 'black'}}>{shoe?.shoe.brand.name} {(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} {shoe?.shoe.version}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Button>

                                </a>
                                
                            </div>
                            
                            
                        )
                    })}
                </div>
            </div>
        )
    }
    
}