import DashboardHeader from "../../../components/Dashboard/Header"
import { useParams, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import PropTypes from 'prop-types';
import { Divider } from "@mui/material"
import ChipComponent from "../../../components/Chip"
import Alert from "@mui/material/Alert";
import ReviewsList from "./Reviews";
import * as React from 'react';
import axios from "axios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from "@mui/material/IconButton";
import Tooltip  from "@mui/material/Tooltip";
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import '../index.css'
import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AskAIPanel from "../../../components/Dashboard/Panels/AskAIPanel";
import SimilarShoesList from "./SimilarShoes";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ScrollRestoration } from "react-router-dom";
import Loader from "../../../components/Loader";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FeedbackSnackbar from "../../../components/Snackar/Feedback Snackbar";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PreviousModelSection from "./PreviousModels";
import AddShoeModal from "../../../components/Modal/AddShoeModal";
import * as Sentry from "@sentry/react";
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PartnerDealsSection from "./PartnerDeals";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
      </div>
    );
  }

  

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function ShoePage () {

    var [ user, userShoes ] = useOutletContext()

    const { isLoading, setIsAlert, setLoading, requestUrl, trackEvent, upgradeModalOpen, setUpgradeModalOpen, sessionUser } = useAppContext()

    const { recommendationId, shoeName } = useParams()

    const navigate = useNavigate()

    var { shoe , preferences, requirements, shoeColorway, tabIndex} = useLocation().state || {}

    var  [ shoeColorway, setShoeColorway ] = useState(shoeColorway || shoe?.shoe.colorways[0])

    var [ pageShoe, setPageShoe ] = useState(shoe)

    const [ websiteReviews, setWebsiteReviews ] = useState()

    var [ userShoe, setUserShoe ] = useState()

    const [ runnerReviews, setRunnerReviews ] = useState()

    const [open, setOpen] = useState(false);

    const [value, setValue] = useState(tabIndex? tabIndex : 0);

    const [addShoeModalOpen, setAddShoeModalOpen] = useState()

    var [averageReviewScore, setAverageReviewScore] = useState(0)

    var [ partnerDeals, setPartnerDeals ] = useState([])

    var [ visibleDeals, setVisibleDeals ] = useState([])


    useEffect(() => {
        if (!shoe) {
            setLoading(true);
            const recommendationUrl = `${requestUrl}/api/recommendations/${recommendationId}`;
            axios({
                url: recommendationUrl,
                withCredentials: true
            })
            .then((res) => {
                const preferences = res.data.recommendation.preferences;
                const requirements = res.data.recommendation.requirements;
                const shoeNameArray = shoeName.split('_');
                const shoeList = res.data.recommendation.shoes.filter(
                    (shoe) => shoe.shoe.brand.slug === shoeNameArray[0] && 
                              shoe.shoe.model === shoeNameArray[1] && 
                              shoe.shoe.version === shoeNameArray[2]
                );
                const selectedShoe = shoeList[0];
                setPageShoe(selectedShoe);
                navigate(`/dashboard/recommendations/${recommendationId}/shoes/${shoeName}`, { 
                    state: { shoe: selectedShoe, preferences, requirements } 
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(isAlert => ({
                    ...isAlert,
                    error: errorMessage
                }));
            });
        } else {
            setLoading(true)
            const shoeReviewsUrl = `${requestUrl}/api/websiteReviews/shoe/${shoe?.shoe._id}`;
            const partnerDealsUrl = `${requestUrl}/api/partnerDeals/shoe/${shoe?.shoe._id}`;
            Promise.all([
                axios({ url: shoeReviewsUrl, withCredentials: true }),
                axios({ url: partnerDealsUrl, withCredentials: true })
            ])
            .then(([reviewsRes, dealsRes]) => {
                setWebsiteReviews(reviewsRes.data);
    
                const currentShoe = userShoes && userShoes.length > 0 
                    ? userShoes?.filter((userShoe) => userShoe?.shoeId?._id === shoe?.shoe._id) 
                    : null;
                setUserShoe(currentShoe ? currentShoe[0] : null);
    
                const colorways = shoe.shoe.shoeShape !== 'foot shaped'
                    ? shoe.shoe.colorways.filter(colorway => 
                        colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption))
                      ).sort()
                    : shoe.shoe.colorways;
                var colorway = colorways.length > 0 ? colorways[0] : shoe.shoe.colorways[0]
                setShoeColorway(colorway);
                setPartnerDeals(dealsRes.data)
                setVisibleDeals(dealsRes.data.filter((deal) => deal.shoeColorway.toString() == colorway._id.toString() && sessionUser.location ? deal.partner.countries.includes(sessionUser.location?.country) : deal && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : null )));
                setLoading(false);
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(isAlert => ({
                    ...isAlert,
                    error: errorMessage
                }));
                setLoading(false);
            });
        }
    }, [shoe]);

    

    const handleClickOpen = () => {
        if (user?.planType && user.planType == 'free') {
            setUpgradeModalOpen(true)
        } else {
            trackEvent('View reviews breakdown', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
            setOpen(true);
        }
        
    };

    const [expanded, setExpanded] = useState(false);

    const handleAccordianClick = (panel) => (event, isExpanded) => {
        trackEvent('View shoe criteria reasoning', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
        setExpanded(isExpanded ? panel : false);
    };
    
    const handleColorwayChange = (evt, colorway) => {
        setShoeColorway(colorway)
        var deals = partnerDeals.filter((deal) => deal.shoeColorway.toString() == colorway._id.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : null ))
        setVisibleDeals(deals)

    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleScroll = () => {
        const element = document.getElementById("reviewsList");
        setTimeout(function() {
           element.scrollIntoView()
        },1)
    }

    const handleCopy = (evt, string) => {
        trackEvent('Copy shoe name', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})
        navigator.clipboard.writeText(string)
        setIsAlert(isAlert => {
            return {
                ...isAlert,
                success: 'Copied to clipboard'
            }
        })
    }

    const handleClick  = (evt) => {
        var url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe.brand.slug}_${shoe?.shoe.model}_${shoe?.shoe.version}/submit-review`
        navigate(url, {state: {shoe: shoe, preferences: preferences, requirements: requirements}})
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const sendToCompare = (evt, shoeList) => {
        var url = `/dashboard/recommendations/${recommendationId}/compare`
        navigate(url, {state: {shoe: [shoeList]}})
        
    }

    var breadcrumb = [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: `${preferences? preferences.shoeUsage : null}, ${preferences? preferences.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        },
        {
            title: `${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}`,
            href: `/dashboard/recommendations/${recommendationId}/${shoeName}`
        }
    
    ]

    

    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader
                breadcrumb={breadcrumb}
                />
                <FeedbackSnackbar />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
    
                        {/* Shoe information tile */}
                        <div className="row mx-1 mx-sm-2 mx-md-5 mx-lg-2 mx-xl-4">
                            {/* Shoe information column */}
                            <div className="col-12 col-xl-9 mx-auto show-shoe-tile py-md-5 px-md-4 px-lg-5">
                                <div className="row">
    
                                    {/* Left side, shoe information, suitability and reviews tabs */}
                                    <div className="col-12 text-center mx-auto col-lg-7 mx-lg-0 order-2 order-lg-1">
    
                                        <div className="row">
                                            <div className="col-7 col-lg-6 mx-auto text-center text-lg-start mx-lg-0">
                                                <img src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe.brand.slug}.png`} alt="" className="img img-fluid my-3"/>
                                            </div>
                                        </div>
    
                                        <div className="row justify-content-center justify-content-md-start">
                                            <div className="col-auto text-center text-lg-start mb-3">
                                                <h1>{shoe?.shoe.brand.name} {(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} {shoe?.shoe.version} (${shoe?.shoe.msrp})</h1>
                                            </div>
                                            <div className="col-auto px-0">
                                                <Tooltip title="Copy">
                                                    <IconButton onClick={(evt) => handleCopy(evt, `${shoe?.shoe.brand.name} ${(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)} ${shoe?.shoe.version} ${user?.runningProfile.footWidth === 'wide' ? (user?.runningProfile.gender === 'male' ? `2E width` : `D width` ) : (user?.runningProfile.footWidth === 'extra-wide' ? (user?.runningProfile.gender === 'male' ? `4E width` : `2e width` ) : null)} ${user?.runningProfile.gender}`)}>
                                                        <ContentCopyIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        
                                        <div className="row mt-md-3">
                                            <div className="col mx-auto mx-lg-2 px-0">
                                                <Tabs 
                                                    value={value}
                                                    onChange={handleChange}
                                                    aria-label="shoeInformationTabs"
                                                    variant="fullWidth"
                                                    scrollButtons="auto"
                                                    allowScrollButtonsMobile
                                                >
                                                    <Tab icon={<InfoOutlinedIcon />} iconPosition="top" label="Details" {...a11yProps(0)} />
                                                    <Tab icon={<PermContactCalendarOutlinedIcon />} iconPosition="top" label={`Suitability (${((shoe?.score/shoe?.maxScore)*100).toFixed(0)}%) `} {...a11yProps(1)} />
                                                    <Tab icon={<QueryStatsIcon />} iconPosition="top" label={`Ratings ${shoe?.shoe.websiteReviews.length && shoe?.shoe.websiteReviews.length > 0 ? `(${(parseFloat(shoe?.shoe.averageWebsiteScore)/2).toFixed(1)}/5)` : "" }`} {...a11yProps(2)}/>
                                                </Tabs>
                                            </div>             
                                        </div>
                                        <div className="row">
                                            <div className="col px-0">
                                                <Divider/>
                                            </div>
                                        </div>
    
                                        <div className="row">
                                            <div className="col px-0 ">
    
    
                                                {/* Overview tab */}
                                            <CustomTabPanel className="px-0" value={value} index={0}>
    
                                                <div className="row mt-3">
                                                    <div className="col-12 text-start">
                                                        <h4>Overview</h4>
                                                    </div> 
                                                </div>
    
                                                
    
    
    
                                                <div className="row mt-3">
                                                    <div className="col-12 text-start">
                                                        <p>{shoe?.shoe.descripton}</p>
                                                    </div>
                                                </div>

                                                <div className="row justify-content-start mb-2">
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.surface} running`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`$${shoe?.shoe.msrp}`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.cushion} cushion`} color={'default'}/>
                                                    </div>
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.stability} stability`} color={'default'}/>
                                                    </div>
                                                    {shoe?.shoe.surface === 'trail' ?
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.grip} grip (${shoe?.shoe.lugDepth}mm lugs)`} color={'default'}/>
                                                    </div>
                                                    : null}
    
    
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.heelToeDrop}mm heel-toe-drop`} color={'default'}/>
                                                    </div>
    
                                                    <div className="col-auto text-start py-1 px-1">
                                                        <ChipComponent size="small" label={`${shoe?.shoe.rocker} rocker`} color={'default'}/>
                                                    </div>
                                                </div>
    
    
    
                                                

                                                {shoe?.shoe.whatsNew && shoe?.shoe.whatsNewList?
                                                <div>

                                                    <Divider className="my-2"/>

                                                    <div className="row mt-3">
                                                        <div className="col-12 text-start">
                                                            <h4>Whats new?</h4>
                                                        </div> 
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 text-start">
                                                            <p>{shoe?.shoe.whatsNew}</p>
                                                        </div>
                                                    </div>
                                                    { shoe?.shoe.whatsNewList.length > 0 ?
                                                    <div className="row">
                                                    <Accordion>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <InfoOutlinedIcon/>
                                                                </div>
                                                                <div className="col-auto">
                                                                    List of updates
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                        <div className="col text-start">
                                                            <ul>
                                                            {shoe?.shoe.whatsNewList.map((trait, index) => {
                                                                return (
                                                                    <li key={`whatsNewListItem${index}`}><p>{trait.title}: {trait.description}</p></li>
                                                                )
                                                            })}

                                                            </ul>                                  
                                                    </div>

                                                        </AccordionDetails>
                                                    </Accordion>
                                                   
                                                </div>
                                                    : null
                                                    }
                                                    

                                                    <PreviousModelSection shoe={shoe} sendToCompare={sendToCompare} setShoeColorway={setShoeColorway} preferences={preferences} requirements={requirements}/>

                                                </div>

                                                : null }
                                                

                                                <Divider className="my-2"/>
                                                
                                                <div className="row mt-3">
                                                    <div className="col-12 text-start">
                                                        <h4>Specs</h4>
                                                    </div> 
                                                </div>
    
                                                <div className="row text-start mt-2">
                                                    <div className="col-6 col-md-4">
                                                        <p>Gender: {shoe?.shoe.gender}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Price: ${shoe?.shoe.msrp}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Colorways: {shoe?.shoe.colorways.length}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Size options: {shoe?.shoe.minSize} - {shoe?.shoe.maxSize} (US)</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Surface: {shoe?.shoe.surface}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Width options: {shoe?.shoe.width.join(', ')} </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Weight: {shoe?.shoe.weight} grams (US 9) </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Rocker: {shoe?.shoe.rocker} </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Cushion: {shoe?.shoe.cushion} ({shoe?.shoe.stackHeight}mm) </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Heel-toe-drop: {shoe?.shoe.heelToeDrop}mm </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Carbon plate: {shoe?.shoe.carbonPlate === 'FALSE' ? 'No' : 'Yes'} </p>
                                                    </div>
                                                    {shoe?.shoe.surface === 'trail' ?
                                                    <div className="col-6 col-md-4">
                                                        <p>Rock plate: {shoe?.shoe.rockPlate === 'FALSE' ? 'No' : 'Yes'} </p>
                                                    </div>
                                                    : null}
                                                    {shoe?.shoe.surface === 'trail' ?
                                                    <div className="col-6 col-md-4">
                                                        <p>Grip: {shoe?.shoe.grip} ({shoe?.shoe.lugDepth}mm, {shoe?.shoe.lugDensity} density lugs )</p>
                                                    </div>
                                                    : null}
                                                    <div className="col-6 col-md-4">
                                                        <p>Water-proof version: {shoe?.shoe.waterproofVersion === 'FALSE' ? 'No' : 'Yes'} </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Latest model: {shoe?.shoe.latestModel === 'FALSE' ? 'No' : 'Yes'} </p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Width rating: {shoe?.shoe.widthRatingForefoot} </p>
                                                    </div>
                                                    
          
                                                        
                                                </div>
    
                                            </CustomTabPanel>
    
    
                                                {/* Suitability tab */}
                                                <CustomTabPanel className="px-0" value={value} index={1}>
                                                    {/* <div className="row mt-3">
                                                        <div className="col-12 text-start">
                                                            <h2>Suitability ({((shoe?.score/shoe?.maxScore)*100).toFixed(0)}%)</h2>
                                                        </div>
                                                    </div> */}
    
                                                    <div className="row mt-1">
                                                        <div className="col-12 text-start">
                                                            <p>Based on your running profile and expected use, this shoe is a {((shoe?.score/shoe?.maxScore)*100).toFixed(0)}% match. Click the dropdowns in each section to learn more about your matching information and the potential impact of a bad decision.</p>
                                                        </div>
                                                    </div>
    
                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            {shoe?.criteria?.sort((a,b) => b.priorityScore - a.priorityScore).map((criteria, index) => {
                                                                var string = criteria.trait.split(/(?=[A-Z])/).join(' ').toLowerCase();
                                                                var finalString = string.charAt(0).toUpperCase() + string.slice(1)
                                                                var criteriaPriority = criteria.priorityScore / shoe.maxScore > 0.15 ? 'High' : criteria.priorityScore / shoe.maxScore > 0.05 ? "Medium" : "Low"
                                                                if (requirements[criteria.trait].reasoning?.length > 0 || criteria.pass == "FALSE") {
                                                                    return (
                                                                        <div key={`${criteria.trait}TraitRow`} className="row align-items-center my-1">
                                                                            <div className="col">
                                                                                <Accordion style={{boxShadow: 'none', border: criteria.pass === 'TRUE' ? '1px solid #EEEBEB' : criteriaPriority === 'High' ? '1px solid red' : (criteriaPriority === 'Medium' ? '1px solid orange' : '1px solid #EEEBEB')}}  expanded={expanded === `panel${index}`} onChange={handleAccordianClick(`panel${index}`)}>
                                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                                                                                        <div className="col">
                                                                                        <div className="row align-items-center justify-content-between">
                                                                                            <div className="col-auto">
                                                                                                <div className="row">
                                                                                                    <div className="col-auto">
                                                                                                        {criteria.pass === 'TRUE' ? <CheckCircleOutlineIcon color="success" /> : (criteriaPriority === "High" ? <ErrorOutlineIcon color="error" /> : criteriaPriority === "Medium" ? <WarningAmberIcon color="warning" /> : <WarningAmberIcon color="warning" /> )  }
                                                                                                    </div>
                                                                                                    <div className="col-auto">
                                                                                                        <h3 className="my-0">{`${finalString}`}</h3>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-auto">
                                                                                                <p className="my-0">Importance: {criteria.priorityScore / shoe.maxScore > 0.15 ? `High` : criteria.priorityScore / shoe.maxScore > 0.05 ? 'Medium' : 'Low'}</p>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                        
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails>
                                                                                        <div className="row my-1 justify-content-evenly">
                                                                                            <div className="col-auto px-5 py-3 trait-tile">
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <h4 className="my-0">Recommended:</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p className="my-0">{requirements[criteria.trait].traits.length ? requirements[criteria.trait].traits.reduce((accu, elem) => {return accu === null ? [elem] : [...accu, ' or ', elem]} ) : Object.entries(requirements[criteria.trait].traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                                
                                                                                            </div>
                                                                                            <div className="col-auto px-5 py-3 trait-tile">
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <h4 className="my-0">This shoe:</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p className="my-0">{Array.isArray(criteria.value) ? criteria.value.join(', ') : criteria.value}</p>
                                                                                                    </div>
                                                                                                </div>  
                                                                                                       
                                                                                            </div>
                                                                                        </div>

                                                                                        {requirements[criteria.trait].description ? 
                                                                                        <div className="row pt-3 px-3">
                                                                                            <div className="col text-start">   
                                                                                                <h4 className="mb-2">Description:</h4>
                                                                                                <p key={`${index}ReasonParagraph`} className="my-2">- {requirements[criteria.trait].description}</p>
                                                                                            </div>
                                                                                        </div>

                                                                                        : null}

                                                                                        {criteria.trait == 'cushionType' ?                                                      
                                                                                        <div className="row px-3">
                                                                                            <div className="col text-start">
                                                                                                <p className="my-0">- {shoe?.shoe.cushionTypeReason}</p>
                                                                                            </div>
                                                                                        </div>  
                                                                                        : null}  
    
                                                                                        <div className="row pt-3 px-3">
                                                                                            <div className="col text-start">   
                                                                                                <h4 className="mb-2">Your needs:</h4>
                                                                                                {requirements[criteria.trait].reasoning?.map((reason, index) => { return <p key={`${index}ReasonParagraph`} className="my-2">- {reason.description} <span><Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={reason.impact}><InfoOutlinedIcon/></Tooltip></span></p> })}    
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row pt-3 px-3">
                                                                                            <div className="col text-start">   
                                                                                                 <img className="img img-fluid" src={`/shoeTraitAssets/${criteria.trait}.png`} alt="" />
                                                                                            </div>
                                                                                        </div>

                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                )} else {
                                                                    return null
                                                                }
                                                                }
                                                            )}
                                                                
                                                                </div>
                                                    </div>
                                                </CustomTabPanel>
    
                                                {/* Reviews tab */}
                                                <CustomTabPanel className="px-0" value={value} index={2}>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col trait-tile mx-2 my-2 py-2">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Reviews on Fittir <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleScroll}>({runnerReviews})</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h3 key={`averageReviewScore${averageReviewScore}`}>{averageReviewScore? `${averageReviewScore}/5` : '-'}</h3>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col trait-tile mx-2 my-2 py-2">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p>Wear-tester reviews <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleClickOpen}>({shoe?.shoe.websiteReviews.length})</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h3>{shoe?.shoe.averageWebsiteScore ? `${(shoe?.shoe.averageWebsiteScore / 2).toFixed(1)}/5` : '-'}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                     
                                                        </div>
                                                    </div>

                                                    {shoe?.shoe.websiteReviews.length > 2 ?
                                                        <div className="row justify-content-start mt-2">
                                                        <AskAIPanel shoe={shoe? shoe : null} />
                                                        </div>
                                                    : null}
                                                    
    
                                                    <div className="row mb-3">
                                                        <div className="col" id="reviewsList">
                                                            <ReviewsList user={user} shoeId={shoe?.shoe._id} setRunnerReviews={setRunnerReviews} averageReviewScore={averageReviewScore} setAverageReviewScore={setAverageReviewScore} />
                                                        </div>
                                                    </div>
    
                                                </CustomTabPanel>
                                            </div>
                                        </div>
    
                                    </div>
    
    
                                    {/* right side, shoe image, and deals */}
                                    <div className="col-11 mx-auto col-lg-5 mx-lg-0 pt-5 text-center order-1 order-lg-2">
                                        <div className="row align-items-center pb-4">
                                            <div className="col">
                                                <img key={`${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoeColorway?.colorwayCode}`} src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe.brand.slug}_${shoe?.shoe.modelSlug}_${shoe?.shoe.version}_${shoeColorway?.colorwayCode}.png`} alt="" className="img img-fluid"/>
                                            </div>
                                        </div>

                                        {/* <div className="row mt-3">
                                                <div className="col">
                                                    <p className="my-0">Color: {shoeColorway?.colorwayName}</p>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col">
                                                    <p className="my-0">Width options: {shoeColorway?.widthOptions?.join(', ')}</p>
                                                </div>
                                        </div> */}

                                        <div className="row justify-content-center mt-3">

                                            <div className="my-1 col-auto">
                                                <Button endIcon={<CompareArrowsIcon />} variant="outlined" size="small" onClick={(evt) => sendToCompare(evt, [shoe])}>
                                                    Compare
                                                </Button>
                                            </div>
                                            
                                            {!userShoe? 
                                            <div className="my-1 col-auto">
                                                <Button endIcon={<DoorSlidingOutlinedIcon/>} onClick={(evt) => setAddShoeModalOpen(true)} variant="outlined" size="small">
                                                    Add to shoe locker
                                                </Button>

                                            </div>
                                            :
                                            <div className="my-1 col-auto">
                                                <Tooltip title={'You own this shoe'}>
                                                    <span>
                                                        <Button disabled variant="outlined" size="small">
                                                            Owned
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                    
                                            </div>
                                            }   
                                            </div>
                                                                                    
                                        <div className="row mt-3 px-2 justify-content-center">
                                        {shoe?.shoe.colorways
                                        .sort((a, b) => {
                                            // Sorting logic: bring colorways with deals to the front
                                            const hasDealA = partnerDeals.some(deal => deal.shoeColorway.toString() === a._id.toString());
                                            const hasDealB = partnerDeals.some(deal => deal.shoeColorway.toString() === b._id.toString());
                                            return hasDealB - hasDealA;
                                        })
                                        .map((colorway, index) => {
                                            // Check if the colorway is available in the required width
                                            var widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' 
                                                ? colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)) 
                                                : true;

                                            // Check if the colorway has a deal
                                            const hasDeal = partnerDeals.some(deal => deal.shoeColorway.toString() === colorway._id.toString());

                                            return (
                                                <div 
                                                    className="col-2 mx-1 my-1" 
                                                    style={{cursor: widthAvailable ? 'pointer' : 'default' }} 
                                                    key={`${shoe.shoe._id}Colorway${index}`} 
                                                    onClick={(evt) => handleColorwayChange(evt, colorway)}
                                                >
                                                    <div className="row">
                                                        <div 
                                                            className="col py-2" 
                                                            style={{
                                                                border: shoeColorway?.colorwayCode === colorway.colorwayCode 
                                                                    ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') 
                                                                    : '1px solid #D9D9D9', 
                                                                borderRadius: '0.5em', 
                                                                opacity: widthAvailable ? '1' : '0.3'
                                                            }}
                                                        >
                                                            <img 
                                                                className='img-fluid' 
                                                                src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} 
                                                                alt="" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        </div>


                                        <div className="row mt-4" id="retailOffers">
                                            <PartnerDealsSection key={`partnerDealsSection${visibleDeals?.length}${shoeColorway?._id}`} user={user} shoe={shoe?.shoe} colorway={shoeColorway} partnerDeals={visibleDeals}  />
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
    
    
                            {/* Ask AI and similar shoes column */}
                            <div className="col-12 col-xl my-3 my-xl-0 ms-xl-3">
                                <div className="row">
                                <SimilarShoesList recommendationId={recommendationId} shoe={shoe} preferences={preferences} requirements={requirements} shoeColorway={shoeColorway} setShoeColorway={setShoeColorway} />
                                </div>
                               
                            </div>
                        </div>
                </div>
    
    
    
                {/* Modal for review information */}
                {shoe?.shoe.averageWebsiteScore && shoe?.shoe.websiteReviews && shoe?.shoe.websiteReviews.length > 0? 
                <React.Fragment>
                    <Dialog
                            onClose={handleClose}
                            fullWidth={true}
                            maxWidth={'lg'}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                        <div className="row px-3 py-3">
                            <div className="col">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <h3 className="my-0">Reviews analysis</h3>
                                    </div>
                                    <div className="col text-end">
                                        <IconButton onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <p>Across the {shoe?.shoe.websiteReviews.length} long-form, professional reviews analysed from google, here is the summary of the pros and cons that were mentioned most frequently. </p>
                                    </div>
                                </div>
                                <div className="row">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="prosAndConsTabsExample"
                                >
                                    <Tab label="Reviews list" {...a11yProps(2)} />
                                </Tabs>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <CustomTabPanel value={value} index={2}>
                                            <div className="col">
                                                
                                                    {websiteReviews?.map((websiteReview, index) => {
                                                        return (
                                                            <div className="row my-2" key={`${websiteReview._id} review summary`}>
                                                                <div className="col trait-tile">
                                                                    <div className="row mt-2">
                                                                        <div className="col">
                                                                            <a style={{textDecoration: 'none'}} target="_blank" href={websiteReview.url}><p style={{ wordBreak: 'break-all' }}>{websiteReview.url}</p></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                           <p style={{ wordBreak: 'break-all' }}>{websiteReview.score !== "" ? `Score: ${(parseFloat(websiteReview.score)/2).toFixed(2)}/5` : `Score: No score available`}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col">
                                                                           <Accordion style={{boxShadow: 'none'}}>
                                                                           <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls={`panel${index}-content`}
                                                                                id={`panel${index}-content`}
                                                            
                                                                                >
                                                                                <p>Summary</p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <p>Pros</p>
                                                                                        <p>{websiteReview.prosSummary}</p>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <p>Cons</p>
                                                                                        <p>{websiteReview.consSummary}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </AccordionDetails>
                                                                           </Accordion>
                                                                        </div>
                                                                    </div>
                                                                    

                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            
                                            </div>
                                        
                                        </CustomTabPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </React.Fragment>
                : null}
                <ScrollRestoration />
                <AddShoeModal selectedShoe={pageShoe} openState={addShoeModalOpen} setOpenState={setAddShoeModalOpen} />
                {/* <PurchaseShoeModal openState={purchaseShoeModalOpen} setOpenState={setPurchaseShoeModalOpen} shoe={shoe} colorway={shoeColorway} requirements={requirements} /> */}
            </div>
        )
    }
    
} 