import { Form, useNavigate, useLocation, Link } from "react-router-dom"
import { useState, useEffect, Suspense, useContext } from "react"
import axios from "axios"
import FormHeader from "../../../components/Form/FormHeader"
import FormFooter from "../../../components/Form/FormFooter"
import FormBody from "../../../components/Form/FormBody"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FlashAlert from "../../../components/Alert"
import { Accordion, AccordionDetails, AccordionSummary, AlertTitle, Button } from "@mui/material"
import ChipComponent from "../../../components/Chip"
import { useAppContext } from '../../../utils/ContextProvider.jsx';
import TextField from "@mui/material/TextField"
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Backdrop from "@mui/material/Backdrop"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import mixpanel from 'mixpanel-browser';
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import * as Sentry from "@sentry/react";

export default function SubmitPage({ progress, section, isAutoComplete} ) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setIsLoading, isProcessing, setProcessing, requestUrl, userLocation } = useAppContext()

    var { recommendedShoe, recommendation, alert } = useLocation().state || {}

    var [recommendationId, setRecommendationId] = useState()

    var [recommendedShoeObject, setRecommendedShoeObject] = useState()

    var [recommendationObject, setRecommendationObject] = useState()

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        referralType: "",
        referralDetails: ""
    })

    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const navigate = useNavigate()

    useEffect(() => {
        if (recommendedShoe) {
            var shoe = JSON.parse(recommendedShoe)
            setRecommendedShoeObject(shoe)  
        }
        
        if (recommendation) {
            var rec = JSON.parse(recommendation)      
            setRecommendationObject(rec)
        } else {
            navigate('/shoe-finder/intro')
        }

        if (localStorage.getItem('isAuthenticated')) {
            navigate('/dashboard')
        }

    },[])

    const handleChange = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value
        setFormData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    };

    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const handleSubmit = async (evt) => {
        if (formData.name !== '' && formData.email !== "" && formData.password !== "" && formData.referralType !== "") {
            evt.preventDefault()
            setProcessing(true)
            var finalRunningProfileString = window.localStorage.getItem('runningProfile')
            var finalRunningProfileObject = await JSON.parse(finalRunningProfileString)
            const { email, password, name, referralType, referralDetails } = formData
            const url = `${requestUrl}/api/users/register?route=recommendation`
            axios({
                url: url,
                method: "POST",
                data: {
                    email: email,
                    password: password,
                    name: name,
                    referralType: referralType,
                    referralDetails: referralDetails,
                    recommendationId: recommendationObject._id,
                    runningProfile: finalRunningProfileObject,
                    location: userLocation
                },
                withCredentials: true
            }           
            )
            .then((res) => {
                mixpanel.identify(email)
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        success: `Welcome to Fittir, ${formData.name} 👋`
                    }
                })
                setProcessing(false)
                localStorage.removeItem('runningProfile')
                localStorage.setItem( "isAuthenticated", true )
                navigate('/dashboard')
            })
            .catch((err) => {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setProcessing(false)
            })
        } else {
            evt.preventDefault()
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: "Please add the required information to sign-up."
                }
            })
        }
        
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
                <div>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isProcessing}
                    >
                        <CircularProgress color="inherit" />
                </Backdrop>
                </div>
            
            <FormHeader progress={progress} section={section} />
            <div className="container form-body mt-4">
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <h2>Your running shoe recommendation</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <p>Based on your requirements, goals, and preferences...</p>
                    </div>
                </div>
                { recommendedShoe ?
                <div className="row d-inline d-md-none align-items-center">
                    <div className="col-auto py-2 mx-auto text-center grey-outline">
                        <div className="row align-items-center">
                            <div className="col-2 px-1 py-1">
                                    <img className="img img-fluid" src="/team/anthony.png" alt="" />
                                </div>
                            <div className="col-10 pe-2 ps-0 text-start">
                                <Link to='/shoe-finder/submit#sign-up' state= {{
                                    recommendation: recommendation,
                                    recommendedShoe: recommendedShoe
                                    }} style={{textDecoration: 'none'}} ><p className="my-0"> 👋 Sign-up to view your recommendation, other options and to dive into the reasoning behind your recommendation (it's free!) <ArrowDownwardIcon /> </p></Link>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                : null}
                <div className="row align-items-center">
                    {/* Recommendation 1 row */}
                    {recommendedShoe 
                    ? 
                    <div className="col-11 col-md-5 mx-auto">
                        <div className="row">
                            <div className="col-auto text-center">
                                {alert?
                                    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'middle' }}>
                                        <Alert
                                        onClose={handleClose}
                                        severity="success"
                                        >
                                        {alert}
                                        </Alert>
                                    </Snackbar>
                                : null}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto mt-2">
                                <ChipComponent 
                                    color={"success"}
                                    label={((recommendedShoeObject?.score / recommendedShoeObject?.maxScore) * 100).toFixed(0) > 95 ? "Perfect match" : (((recommendedShoeObject?.score / recommendedShoeObject?.maxScore) * 100).toFixed(0) > 85 ? "Great option" : "Good option") }
                            
                                    size={"large"}
                                    icon={<ThumbUpOffAltIcon />}
                                />
                            </div>
                            { recommendedShoe && (recommendedShoeObject?.score / recommendedShoeObject?.maxScore) < 1.0 ?
                                <div className="col-auto mt-2">
                                    <ChipComponent
                                        icon={<InfoOutlinedIcon />}
                                        size='large'
                                        label={`Better option from brand outside preferences`}
                                        color={'default'}
                                    />
                                </div>
                            : null }
                        </div>
                        <div className="row mt-2">
                            <div className="col-7 mx-auto text-center">
                                <img draggable="false" className="img img-fluid unselectable" style={{filter: 'blur(10px)', pointerEvents: 'none'}} src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${recommendedShoeObject?.shoe.brand.slug}.png`} alt="" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-11 mx-auto text-center">
                                <img draggable="false" className="img img-fluid unselectable" style={{filter: 'blur(12px)', pointerEvents: 'none'}} src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${recommendedShoeObject?.shoe.brand.slug}_${recommendedShoeObject?.shoe.modelSlug}_${recommendedShoeObject?.shoe.version}_${recommendedShoeObject?.shoe.colorways[0]?.colorwayCode}.png`} alt="" />
                            </div>
                        </div>
                        
                        <div className="row mt-4">
                            <div className="col-10 mx-auto text-center">
                                <h1 className="unselectable" style={{filter: 'blur(10px)'}}>{recommendedShoeObject?.shoe.brand.name} {recommendedShoeObject?.shoe.model} {recommendedShoeObject?.shoe.version}</h1>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 mx-auto text-center">
                                <p style={{filter: 'blur(10px)'}} className="d-none d-md-inline unselectable">{recommendedShoeObject?.shoe.descripton}</p>
                            </div>
                        </div>
                        
                        <div className="row mt-3 px-2 justify-content-center">
                        {recommendedShoeObject?.criteria.map((criteria, index) => {
                            var string = criteria.trait.split(/(?=[A-Z])/);
                            var finalString = string.join(' ').toLowerCase()
                            if (recommendationObject?.requirements[criteria.trait].reasoning.length > 0) {
                                return (
                                    <div className="col-auto mx-md-0 px-1 my-1">
                                        <ChipComponent 
                                            icon={criteria.pass === "TRUE" ? <CheckCircleOutlineIcon/> : <ErrorOutlineIcon /> }
                                            label={`${finalString}: ${recommendedShoeObject?.shoe[criteria.trait]} ${criteria.pass === "FALSE" ? `(Recommended: ${recommendationObject?.requirements[criteria.trait].traits.length > 0 ? recommendationObject?.requirements[criteria.trait].traits.join(', ') : Object.entries(recommendationObject?.requirements[criteria.trait].traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')})` : ""}`}
                                            size="small"
                                            variant={"outlined"}
                                            color={criteria.pass === "TRUE" ? "success" : "warning" }
                                                />
                                    </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="row mt-3">
                            <div className="col-8 mx-auto text-center">
                                
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-11 col-md-5 mx-auto pt-4">
                        <p><i>We couldn't find any shoes that matched your preferences. <Link to={'/shoe-finder/preferences/brand'}>Try expanding your brand or price preferences.</Link></i></p>
                    </div>
                    }
                    
                    {/* Signup prompt row */}
                    <div className="col-11 col-md-5 mx-auto px-2 px-md-4 py-5 grey-outline" id="sign-up">
                        {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
                        <div className="row mt-3">
                            <div className="col-11 mx-auto text-center">
                                <img className="img img-fluid" src="/logos/logo-primary.svg" alt="" />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-11 mx-auto text-center">
                                <h1>Sign up to view your recommended shoe, and compare all brands</h1>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-11 mx-auto text-center">
                                <p>Fittir is free-to-use. You can compare across all brands, learn more about your requirements and read reviews from similar runners.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-11 mx-auto text-center">
                            <form className="validated-form" method="POST" id="registerForm" onSubmit={handleSubmit}>
                                <div className="row mb-5">
                                <div className="col">
                                    <div className="row mt-3">
                                        <div className="col">
                                        <TextField fullWidth id="email" type="email" name="email" placeholder="name@example.com" onChange={handleChange} label="Email" variant="outlined" />
                                        </div>
                                    </div>  
                                    <div className="row mt-3">
                                        <div className="col">
                                            <TextField fullWidth type="text" className="form-control" name="name" id="name" placeholder="Full name" onChange={handleChange} label="First name" variant="outlined" />
                                        </div>
                                    </div>  
                                    <div className="row mt-3">
                                            <div className="col">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="password">Password</InputLabel>
                                                    <OutlinedInput
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        onChange={handleChange}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                    </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col text-start">
                                                <FormControl fullWidth>
                                                    <InputLabel id="referral-type-select-label">How did you hear about Fittir</InputLabel>
                                                    <Select
                                                    labelId="referral-type-select-label"
                                                    id="referral-type-select"
                                                    name="referralType"
                                                    value={formData?.referralType}
                                                    label="How did you hear about Fittir?"
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '30vh', // Set the max height here
                                                        },
                                                        },
                                                    }}
                                                    >
                                                        <MenuItem key="socialMediaSelect" value="socialMedia">Social Media (e.g Instagram, TikTok, LinkedIn)</MenuItem>
                                                        <MenuItem key="searchEngineSelect" value="searchEngine">Search Engine (e.g Found on google)</MenuItem>
                                                        <MenuItem key="searchEngineSelect" value="runClubOrOnlineCoach">Run club or Online Coach</MenuItem>
                                                        <MenuItem key="wordOfMouthSelect" value="wordOfMouth">Word of Mouth (e.g from another runner)</MenuItem>
                                                        <MenuItem key="otherSelect" value="other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        {formData?.referralType === 'other' || formData?.referralType === 'runClubOrOnlineCoach' ?
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <TextField fullWidth id="referralDetails" name="referralDetails" onChange={handleChange} label="Referral details" variant="outlined" />
                                                </div>
                                            </div>  
                                        : null}
                                        
                                    </div>     
                                </div>
                                <div className="row mb-2">
                                <div className="col-8 mx-auto text-center">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            disabled={isProcessing == true ? true : false}
                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                        >Register
                                        
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit}  />    
        </div>
                 
    )
}