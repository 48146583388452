import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function FeedbackSnackbar () {

    const [displayPopUp, setDisplayPopUp] = useState(true);

    useEffect(()=> {
        let returningUser = localStorage.getItem("seenPopUp");
        if (returningUser) {
            setDisplayPopUp(!returningUser)
        } else {
            setTimeout(()=> setDisplayPopUp(!returningUser), 2000) 
        }
    },[])



    // when pop-up is closed this function triggers, we pass it to 'onClose' property of the modal
    const closePopUp = () => {
        localStorage.setItem("seenPopUp", true);
        setDisplayPopUp(false);
    };


    return (
        <Snackbar
                open={displayPopUp}
                onClose={closePopUp}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                
            >
                <Alert
                onClose={closePopUp}
                severity="info"
                sx={{ width: '100%' }}
                >
                    Give feedback, win a $20 voucher <a onClick={closePopUp} target="_blank" href="https://forms.gle/1ydvDTRwjqDtqMTU7">Submit feedback</a>
                </Alert>
                
        </Snackbar>
    )



}


    