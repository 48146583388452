import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer"
import { useAppContext } from '../../utils/ContextProvider.jsx'
import { useEffect, useState } from "react"
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom"
import FlashAlert from "../../components/Alert"
import "./index.css"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import axios from "axios"
import * as Sentry from "@sentry/react";
import UpgradePlanModal from "../../components/Dashboard/Modal/UpgradePlanModal"

export default function DashboardPage() {

    const location = useLocation()

    const navigate = useNavigate()

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading, trackEvent, sessionUser, setSessionUser } = useAppContext()

    var [user, setUser] = useState()

    var [userShoes, setUserShoes] = useState()

    var [fetchingInfo, setFetchingInfo] = useState()
  
    useEffect(() => {
        const url = `${requestUrl}/api/userShoes`;
        const userUrl = `${requestUrl}/api/users`;
        const query = new URLSearchParams(window.location.search);
        const isPaymentSuccess = query.get("success");
    
        const userShoesRequest = axios({
            url: url,
            method: "GET",
            withCredentials: true
        });
    
        const userRequest = axios({
            url: isPaymentSuccess ? `${userUrl}?paymentReferral=true` : userUrl,
            method: "GET",
            withCredentials: true
        });
    
        Promise.all([userShoesRequest, userRequest])
            .then(([userShoesRes, userRes]) => {
                setUserShoes(userShoesRes.data.userShoes);
                const user = userRes.data.user;
                setUser(user);
                setSessionUser(user);
    
                if (!user) {
                    localStorage.removeItem('isAuthenticated');
                    setIsAlert(pageData => ({
                        ...pageData,
                        error: 'Please login to view your dashboard'
                    }));
                    navigate('/auth/login');
                }
    
                if (isPaymentSuccess) {
                    trackEvent('Payment completed');
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
                navigate('/auth/login');
            });
    }, [navigate, requestUrl]);

    return (
        <div className="d-flex flex-column vh-100 px-0 py-0">
            <NavBar />
                {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
                {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
                <Outlet context={[user, userShoes]} />
            <Footer />
            <UpgradePlanModal user={user} />
            <ScrollRestoration/>
        </div>
    )
    
}