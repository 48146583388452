import * as React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import mixpanel from 'mixpanel-browser';
import CircularProgress from '@mui/material/CircularProgress';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import * as Sentry from "@sentry/react";

export default function PositionedMenu() {
    const navigate = useNavigate()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, isProcessing, setProcessing, sessionUser, setSessionUser } = useAppContext()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        
    };

    const handleClose = (evt) => {
        setAnchorEl(null);
    };

    const logOutUser = (evt) => {
        setProcessing(true)
        const url = `${requestUrl}/api/users/logout`
        axios({
            url: url,
            method: "POST",
            withCredentials: true
        })
        .then((res) => {
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("userInfo");
            mixpanel.reset()
            setIsAlert(isAlert => {
              return {
                  ...isAlert,
                  success: `Logged out, see you next time 👋 `
              }
            })
            setProcessing(false)
            setSessionUser({})
            navigate("/")
        })
        .catch((err) => {
          Sentry.captureException(err)
          var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
          setIsAlert(isAlert => {
            return {
                ...isAlert,
                error: errorMessage
            }
          })
          setProcessing(false)
        })
    }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        endIcon={<ArrowDropDownIcon />}
        variant='contained'
        size='large'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        className='d-block d-lg-none'
        id="demo-positioned-menu"
        data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard'>
          <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            Home
          </MenuItem>
        </Link>
        <Link to="/dashboard/profile" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard/account'>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Link>
        <Link to="/dashboard/shoe-locker" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard/account'>
            <ListItemIcon>
              <DoorSlidingOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Shoe Locker
          </MenuItem>
        </Link>
        <Link to="mailto:hi@fittir.io?subject=Support: " style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href="mailto:hi@fittir.io?subject=Support: ">
            <ListItemIcon>
              <HelpOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Support
          </MenuItem>
        </Link>
        <form><MenuItem 
            onClick={logOutUser}
            disabled={isProcessing ? true : false}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem></form>
        
      </Menu>
      <Menu
        className='d-none d-lg-block'
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard'>
          <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            Home
          </MenuItem>
        </Link>
        <Link to="/dashboard/profile" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard/account'>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Link>
        <Link to="/dashboard/shoe-locker" style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href='/dashboard/account'>
            <ListItemIcon>
              <DoorSlidingOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Shoe Locker
          </MenuItem>
        </Link>
        <Link to="mailto:hi@fittir.io?subject=Support: " style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem onClick={handleClose} href="mailto:hi@fittir.io?subject=Support: ">
            <ListItemIcon>
              <HelpOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Support
          </MenuItem>
        </Link>
        <form><MenuItem 
            onClick={logOutUser}
            disabled={isProcessing ? true : false}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem></form>
        
      </Menu>
    </div>
  );
}