import DashboardHeader from "../../../components/Dashboard/Header"
import axios from "axios"
import { useAppContext } from "../../../utils/ContextProvider.jsx/index.jsx"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Loader from "../../../components/Loader"
import "../index.css"
import  TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { runningProfileOptions } from "../../../utils/ProfileOptions/index"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import CircularProgress from "@mui/material/CircularProgress"
import { Backdrop } from "@mui/material"
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom"
import ProcessingBackdrop from "../../../components/Backdrop"


export default function ProfilePage() {

    // var carousel = [
    //     {
    //         content: 
    //             <div>
    //                 <h1>Slide 1</h1>
    //             </div>
    //     },
    //     {
    //         content: 
    //             <div>
    //                 <h1>Slide 2</h1>
    //             </div>
    //     }
    // ]

    var breadcrumb = [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Profile'
        }
    
    ]

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, setProcessing, isProcessing, requestUrl, trackEvent } = useAppContext()
    const navigate = useNavigate()

    const [user, setUser] = useState({})

    const [accountInformation, setAccountInformation] = useState({
        name: "",
        email: ""
    })

    const [runningProfile, setRunningProfile] = useState({
        age: null,
        archType: null,
        experience: null,
        footStrike: null,
        footWidth: null,
        gender: null,
        goal: null,
        height: null,
        pace: null,
        pronation: null,
        weeklyDistance: null,
        weight: null,
        currentShoe: null,
        shoeSize: null
    })

    useEffect(() => {
        setLoading(true)
        var url = `${requestUrl}/api/users`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            var user = res.data.user
            setUser(user)
            setAccountInformation(currData => {
                return {
                    ...currData,
                    name: user.name,
                    email: user.email
                }
            })
            if (user.runningProfile) {
                setRunningProfile(currData => {
                    return {
                        ...currData,
                        age: user.runningProfile.age,
                        archType: user.runningProfile.archType,
                        experience: user.runningProfile.experience,
                        footStrike: user.runningProfile.footStrike,
                        footWidth: user.runningProfile.footWidth,
                        gender: user.runningProfile.gender,
                        goal: user.runningProfile.goal,
                        height: user.runningProfile.height,
                        pace: user.runningProfile.pace,
                        pronation: user.runningProfile.pronation,
                        weeklyDistance: user.runningProfile.weeklyDistance,
                        weight: user.runningProfile.weight,
                        currentShoe: user.runningProfile.currentShoe? user.runningProfile.currentShoe._id : null,
                        shoeSize: user.runningProfile.shoeSize? user.runningProfile.shoeSize : null
    
                    }
                })
            }
            
            setLoading(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
            localStorage.clear('isAuthenticated')
            navigate('/dashboard')
        })
    }, [])

    const resetPassword = (evt) => {
        navigate('/auth/forgot-password')
    }
    const resetEmail = (evt) => {
        navigate('/auth/update-email')
    }

   const handleChange = (evt) => {
        var target = evt.target.name
        var value = evt.target.value
        trackEvent('Update profile field', {profileField: evt.target.name})
        setRunningProfile(currData => {
            return {
                ...currData,
                [target]: value

            }
        })
   }

   const handleTextChange = (evt) => {
        var target = evt.target.name
        var value = evt.target.value
        setAccountInformation(currData => {
            return {
                ...currData,
                [target]: value

            }
        })
   }

   const handleSubmit = (evt, route, data) => {
        setProcessing(true)
        trackEvent('Profile updated')
        var userId = user._id.toString()
        var url = `${requestUrl}/api/users/${userId}`
        axios({
            url: url,
            method: "PUT",
            data: {
                data: data,
                section: route
            },
            withCredentials: true
        }).then((res) => {
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    success: "Profile (and recommendations) updated!"
                }
            })
            setProcessing(false)
            navigate('/dashboard')
        }).catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setProcessing(false)

        })

   }

    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div>
                <ProcessingBackdrop isProcessing={isProcessing} />
                </div>
                <DashboardHeader
                    heading={`Profile`}
                    subHeading={'Update your account information, password and running profile that informs your recommendations.'}
                    breadcrumb={breadcrumb}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                    <div className="container-md">
                        {/* Account information tile */}
                        <div className="row">
                            <div className="col-11 col-md-10 mx-auto px-4 py-4 profile-information-tile">
                                <div className="row">
                                    <div className="col-auto">
                                        <h2>Account</h2>
                                    </div>
                                </div>
                                {user?.planType ?
                                <div className="row mt-2">
                                    <div className="col">
                                        <h4>Plan type: {user?.planType}</h4>
                                    </div>
                                </div>
                                : null}
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 mt-2">
                                        
                                        <div className="row">
                                            <div className="col">
                                                <h4>Name</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <TextField
                                                        fullWidth
                                                        name="name"
                                                        id="filled-hidden-label-normal"
                                                        defaultValue={user?.name}
                                                        variant="outlined"
                                                        onChange={handleTextChange}
                                                    />
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col-auto">
                                                <h4>Email</h4>
                                            </div>
                                            {/* <div className="col-auto px-0">
                                                <a onClick={resetEmail}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>(Update)</p></a>
                                            </div> */}
                                            <div className="row">
                                                <div className="col">
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        name="email"
                                                        id="filled-hidden-label-normal"
                                                        defaultValue={user?.email}
                                                        variant="outlined"
                                                        onChange={handleTextChange}
                                                    />
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col-auto">
                                                <h4>Password</h4>
                                            </div>
                                            <div className="col-auto px-0">
                                                <a onClick={resetPassword}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>(Update)</p></a>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <TextField
                                                        disabled
                                                        fullWidth
                                                        id="filled-hidden-label-normal"
                                                        type="password"
                                                        defaultValue="abc1234"
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <Button 
                                            variant="contained"
                                            onClick={(e) => {handleSubmit(e, 'account', accountInformation)}}
                                            disabled={isProcessing == true ? true : false}
                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                        > 
                                        Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Running profile tile, only if user has a profile created */}
                        {user.runningProfile?
                        <div className="row mt-3">
                            <div className="col-11 col-md-10 mx-auto px-4 py-4 profile-information-tile">
                                <div className="row">
                                    <div className="col-auto">
                                        <h2>Running profile</h2>
                                    </div>
                                </div>
                                {/* Age, weight and height dropdowns */}
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Age</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.age}
                                                    onChange={handleChange}
                                                    name="age"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.age.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Height</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.height}
                                                    onChange={handleChange}
                                                    name="height"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.height.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Weight</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.weight}
                                                    onChange={handleChange}
                                                    name="weight"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.weight.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* Pace, goal and experience dropdowns */}
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Experience</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.experience}
                                                    onChange={handleChange}
                                                    name="experience"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.experience.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Goal</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.goal}
                                                    onChange={handleChange}
                                                    name="goal"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.goal.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Pace</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.pace}
                                                    onChange={handleChange}
                                                    name="pace"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.pace.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* Foot strike, pronation and arch type dropdowns */}
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Foot-strike</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.footStrike}
                                                    onChange={handleChange}
                                                    name="footStrike"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.footStrike.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Pronation</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.pronation}
                                                    onChange={handleChange}
                                                    name="pronation"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.pronation.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Arch height</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.archType}
                                                    onChange={handleChange}
                                                    name="archType"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.archType.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Foot-width</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.footWidth}
                                                    onChange={handleChange}
                                                    name="footWidth"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.footWidth.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Gender</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={runningProfile?.gender}
                                                    onChange={handleChange}
                                                    name="gender"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {runningProfileOptions.gender.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Shoe size</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    {runningProfile?.gender == 'female' ?
                                                        <Select
                                                            fullWidth
                                                            id="shoe-size-select"
                                                            value={runningProfile?.shoeSize}
                                                            onChange={handleChange}
                                                            name="shoeSize"
                                                            MenuProps={{
                                                                PaperProps: {
                                                                style: {
                                                                    maxHeight: '50vh',
                                                                },
                                                                },
                                                            }}
                                                            >
                                                            <MenuItem value={"4"}>4 US (2 UK)</MenuItem>
                                                            <MenuItem value={"4.5"}>4.5 US (2.5 UK)</MenuItem>
                                                            <MenuItem value={"5"}>5 US (3 UK)</MenuItem>
                                                            <MenuItem value={"5.5"}>5.5 US (3.5 UK)</MenuItem>
                                                            <MenuItem value={"6"}>6 US (4 UK)</MenuItem>
                                                            <MenuItem value={"6.5"}>6.5 US (4.5 UK)</MenuItem>
                                                            <MenuItem value={"7"}>7 US (5 UK)</MenuItem>
                                                            <MenuItem value={"7.5"}>7.5 US (5.5 UK)</MenuItem>
                                                            <MenuItem value={"8"}>8 US (6 UK)</MenuItem>
                                                            <MenuItem value={"8.5"}>8.5 US (6.5 UK)</MenuItem>
                                                            <MenuItem value={"9"}>9 US (7 UK)</MenuItem>
                                                            <MenuItem value={"9.5"}>9.5 US (7.5 UK)</MenuItem>
                                                            <MenuItem value={"10"}>10 US (8 UK)</MenuItem>
                                                            <MenuItem value={"10.5"}>10.5 US (8.5 UK)</MenuItem>
                                                            <MenuItem value={"11"}>11 US (9 UK)</MenuItem>
                                                            <MenuItem value={"11.5"}>11.5 US (9.5 UK)</MenuItem>
                                                            <MenuItem value={"12"}>12 US (10 UK)</MenuItem>
                                                            <MenuItem value={"12.5"}>12.5 US (10.5 UK)</MenuItem>
                                                            <MenuItem value={"13"}>13 US (11 UK)</MenuItem>
                                                        </Select>
                                                    : 
                                                        <Select
                                                            fullWidth
                                                            id="shoe-size-select"
                                                            value={runningProfile?.shoeSize}
                                                            onChange={handleChange}
                                                            name="shoeSize"
                                                            MenuProps={{
                                                                PaperProps: {
                                                                style: {
                                                                    maxHeight: '50vh',
                                                                },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={"5"}>5 US (4 UK)</MenuItem>
                                                            <MenuItem value={"5.5"}>5.5 US (4.5 UK)</MenuItem>
                                                            <MenuItem value={"6"}>6 US (5 UK)</MenuItem>
                                                            <MenuItem value={"6.5"}>6.5 US (5.5 UK)</MenuItem>
                                                            <MenuItem value={"7"}>7 US (6 UK)</MenuItem>
                                                            <MenuItem value={"7.5"}>7.5 US (6.5 UK)</MenuItem>
                                                            <MenuItem value={"8"}>8 US (7 UK)</MenuItem>
                                                            <MenuItem value={"8.5"}>8.5 US (7.5 UK)</MenuItem>
                                                            <MenuItem value={"9"}>9 US (8 UK)</MenuItem>
                                                            <MenuItem value={"9.5"}>9.5 US (8.5 UK)</MenuItem>
                                                            <MenuItem value={"10"}>10 US (9 UK)</MenuItem>
                                                            <MenuItem value={"10.5"}>10.5 US (9.5 UK)</MenuItem>
                                                            <MenuItem value={"11"}>11 US (10 UK)</MenuItem>
                                                            <MenuItem value={"11.5"}>11.5 US (10.5 UK)</MenuItem>
                                                            <MenuItem value={"12"}>12 US (11 UK)</MenuItem>
                                                            <MenuItem value={"12.5"}>12.5 US (11.5 UK)</MenuItem>
                                                            <MenuItem value={"13"}>13 US (12 UK)</MenuItem>
                                                            <MenuItem value={"13.5"}>13.5 US (12.5 UK)</MenuItem>
                                                            <MenuItem value={"14"}>14 US (13 UK)</MenuItem>
                                                            <MenuItem value={"14.5"}>14.5 US (13.5 UK)</MenuItem>
                                                            <MenuItem value={"15"}>15 US (14 UK)</MenuItem>
                                                            <MenuItem value={"15.5"}>15.5 US (14.5 UK)</MenuItem>
                                                            <MenuItem value={"16"}>16 US (15 UK)</MenuItem>
                                                        </Select>
                        }
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <Button 
                                            variant="contained"
                                            onClick={(e) => {handleSubmit(e, 'runningProfile', runningProfile)}}
                                            disabled={isProcessing == true ? true : false}
                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                        > 
                                        Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}


                        {/* Current running shoe, only if user has a profile created
                        {user.runningProfile?
                        <div className="row mt-3">
                            <div className="col-11 col-md-10 mx-auto px-4 py-4 profile-information-tile">
                                <div className="row">
                                    <div className="col-auto">
                                        <h2>Current running shoe</h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <CurrentShoeCard user={user} handleSubmit={handleSubmit} isProcessing={isProcessing} setProcessing={setProcessing} />
                                </div>
                            </div>
                        </div>
                        : null} */}
                    </div>
                </div>
            </div>
            
        )
    }
}