var sortingMethodsRecommendation = {
    recommended: { method: (a, b) => {
        return b.score - a.score ||  parseFloat(b.shoe.averageWebsiteScore).toFixed(1)  - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || b.shoe.websiteReviews.length - a.shoe.websiteReviews.length
    } },
    price: { method: (a, b) => {return a.shoe.msrp - b.shoe.msrp} },
    popularity: { method: (a, b) => {return b.shoe.websiteReviews.length - a.shoe.websiteReviews.length;} },
    weight: {method: (a, b) => {return a.shoe.weight - b.shoe.weight;}},
    ratings: {method: (a, b) => {return b.shoe.averageWebsiteScore - a.shoe.averageWebsiteScore;}},
    cushion: {method: (a, b) => {return b.shoe.stackHeight - a.shoe.stackHeight;}},
    new: {
        method: (a, b) => {
            // Convert `createdAt` to a timestamp, use -Infinity for missing values
            const createdAtA = a.shoe.createdAt ? new Date(a.shoe.createdAt).getTime() : -Infinity;
            const createdAtB = b.shoe.createdAt ? new Date(b.shoe.createdAt).getTime() : -Infinity;

            // Sort in descending order (newest first)
            return createdAtB - createdAtA;
        }
    },
  };

var sortingMethodsShoes = {
    price: { method: (a, b) => {return a.msrp - b.msrp}},
    brand: { method: (a, b) => {return a.brand.name - b.brand.name || a.modelSlug - b.modelSlug} },
    popularity: { method: (a, b) => {return b.websiteReviews.length - a.websiteReviews.length;} },
    weight: {method: (a, b) => {return a.weight - b.weight;}},
    ratings: {method: (a, b) => {return b.averageWebsiteScore - a.averageWebsiteScore;}},
    cushion: {method: (a, b) => {return b.stackHeight - a.stackHeight;}},
    new: {
        method: (a, b) => {
            if (!b.createdAt) return -1; // If b doesn't have createdAt, put it last
            if (!a.createdAt) return 1;  // If a doesn't have createdAt, put it last
            return b.createdAt - a.createdAt; // Sort by createdAt when both exist
        }
    },
  };

export { sortingMethodsRecommendation, sortingMethodsShoes }