var preferencesOptions = {
    shoeSurface: [
        {
            title: "Road",
            subHeading: null,
            name: "shoeSurface",
            value: "road"
    
        },
        {
            title: "Trail",
            subHeading: null,
            name: "shoeSurface",
            value: "trail"
    
        },
        {
            title: "Road to trail",
            subHeading: "(Best for light trails and short stints on road)",
            name: "shoeSurface",
            value: "hybrid"
    
        }
    ],
    shoeUsage: [
        {
            title: "A bit of everything..",
            subHeading: "(everyday shoe)",
            name: "shoeUsage",
            value: "everyday"
    
        },
        {
            title: "Long training runs & events",
            subHeading: "(long distance shoe)",
            name: "shoeUsage",
            value: "long_distance"
    
        },
        {
            title: "Speed work & racing",
            subHeading: "(speed shoe)",
            name: "shoeUsage",
            value: "speed"
    
        }
    ],
    shoeBrands: [
        {
            title: "0-18y/o",
            subHeading: null,
            name: "age",
            value: "0-18y/o"
    
        },
        {
            title: "19-35y/o",
            subHeading: null,
            name: "age",
            value: "19-35y/o"
    
        },
        {
            title: "36-50y/o",
            subHeading: null,
            name: "age",
            value: "36-50y/o"
    
        }
    ],
    shoeBudget: {
        low: 0,
        high: 550
    },
    cushionType: [
        {
            title: "Pillowy soft",
            subHeading: null,
            name: "cushionType",
            value: "soft"
    
        },
        {
            title: "Firm and responsive",
            subHeading: null,
            name: "cushionType",
            value: "firm"
    
        }
        ,
        {
            title: "I don't mind",
            subHeading: null,
            name: "cushionType",
            value: "n/a"
    
        }
    ],
    trailSurface: [
        {
            title: "Light trails",
            subHeading: "e.g gravel roads, walking trails",
            name: "trailSurface",
            value: "light"
    
        },
        {
            title: "Moderate trails",
            subHeading: "e.g maintained single track, mountain-bike trails ",
            name: "trailSurface",
            value: "moderate"
    
        },
        {
            title: "Technical trails",
            subHeading: "e.g muddy trails, back-country, mountain ridge lines ",
            name: "trailSurface",
            value: "technical"
    
        }
    ],
    rockPlate: [
        {
            title: "Yes",
            subHeading: null,
            name: "rockPlate",
            value: "true"
    
        },
        {
            title: "No",
            subHeading: null,
            name: "rockPlate",
            value: "false"
    
        }
    ],
    conditions: [
        {
            title: "Yes",
            subHeading: null,
            name: "conditions",
            value: "wet"
    
        },
        {
            title: "No",
            subHeading: null,
            name: "conditions",
            value: "dry"
    
        }
    ],
    speedType: [
        {
            title: "Lightweight & responsive",
            subHeading: "(best for speed workouts)",
            name: "speedType",
            value: "min"
    
        },
        {
            title: "Max-cushion & carbon-plated",
            subHeading: "(best for race-day)",
            name: "speedType",
            value: "max"
    
        }
    ],
}

export { preferencesOptions }