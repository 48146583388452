var shoeBrands = [
    'Adidas',
'Altra',
'Asics',
'Brooks',
'Hoka',
'Inov-8',
'La sportiva',
'Merrell',
'Mizuno',
'New Balance',
'Norda',
'Nike',
'Nnormal',
'North Face',
'On Running',
'Puma',
'Salomon',
'Scarpa',
'Scott',
'Saucony',
'Topo Athletic',
'Vivobarefoot',
'Xero'
]

export { shoeBrands }